//import Axios from "axios";
//import { API_HOST } from "./constants";
//
//let csrfToken = null;
//
//Axios.defaults.withCredentials = true;
//Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
//Axios.defaults.xsrfCookieName = "csrftoken";
//
//async function getCsrfToken() {
//  const response = await Axios.get(`${API_HOST}/csrf/`, {});
//  console.log("----->", response);
//  csrfToken = response.data.csrfToken; // Assuming the token is returned in the response
//  return csrfToken;
//}
//
//export default async function callAPI(method, route, body, signal) {
//  let response;
//  if (method === "POST") {
//    try {
//      Axios.defaults.headers.common["X-CSRFTOKEN"] = await getCsrfToken();
//      console.log("----->", Axios.defaults.headers,body);
//      response = await Axios.post(`${API_HOST}/${route}`, body);
//      console.log(response);
//    } catch (error) {
//      response = { data: { msg: "fail" } };
//    }
//  } else if (method === "GET") {
//    response = await Axios.get(`${API_HOST}/${route}`, {
//      signal,
//    });
//    console.log(response);
//  }
//
//  const data = await response.data;
//  return data;
//}
import Axios from "axios";
import { API_HOST } from "./constants";

let authToken = null;

Axios.defaults.withCredentials = true;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
async function getCsrfToken() {
  const response = await Axios.get(`${API_HOST}/csrf/`, {});
  console.log("----->", response);
  const csrfToken = response.data.csrfToken; // Assuming the token is returned in the response
  return csrfToken;
}

export default async function callAPI(method, route, body, headers = {}) {
  let response;
  if (method === "POST") {
    try {
      Axios.defaults.headers.common["X-CSRFTOKEN"] = await getCsrfToken();
      response = await Axios.post(`${API_HOST}/${route}`, body, {
        headers: { ...headers },
      });
    } catch (error) {
      response = { data: { msg: "fail" } };
    }
  } else if (method === "GET") {
    try {
      response = await Axios.get(`${API_HOST}/${route}`, {
        headers: { ...headers },
        //        params: body // assuming body contains query parameters for GET request
      });
    } catch (error) {
      response = { data: { msg: "fail" } };
    }
  }

  const data = await response.data;
  return data;
}
