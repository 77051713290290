/* .container > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text {
    pointer-events: none;
    justify-content: start !important;
    font-family: 'Kanit', sans-serif;
    line-height: 0px;
    text-transform: uppercase;
  }
  
  .number {
    font-size: 300px;
    color: #545864;
  }
  
  .number span {
    display: inline-block;
    position: relative;
  }
  
  .slopeBegin {
    background-color: #20232f;
    clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%);
  }
  
  .slopeEnd {
    clip-path: polygon(70% 0, 100% 0, 80% 100%, 50% 100%);
  }
  
  .slopeBegin,
  .slopeEnd {
    position: absolute;
    width: 170%;
    height: 100%;
    cursor: pointer;
  }
  
  .pink {
    background: linear-gradient(to right, deeppink 0%, coral 100%);
  }
  
  .teal {
    background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
  }
  
  .tomato {
    background: linear-gradient(to right, tomato 0%, gold 100%);
  }
   */

   .maincontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    background: #dfdfdf;
  }
  
  .videoContainer {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .loginBtn {
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    padding: 1rem;
    background: transparent;
  }
  
  .footerLogo {
    height: 1rem;
  }
  
  /* Media Queries for Responsive Design */
  
  /* Large devices (desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .loginBtn {
      font-size: 1.5rem;
      padding: 12px 24px;
    }
    
    .footerLogo {
      height: 2rem;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 1199px) {
    .loginBtn {
      font-size: 1.3rem;
      padding: 11px 22px;
    }
    
    .footerLogo {
      height: 1.8rem;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767px) {
    .loginBtn {
      font-size: 1.2rem;
      padding: 10px 20px;
    }
    
    .footerLogo {
      height: 1.6rem;
    }
  }
  
  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575px) {
    .loginBtn {
      font-size: 1rem;
      padding: 8px 16px;
    }
    
    .footerLogo {
      height: 1.5rem;
    }
  }
  
  