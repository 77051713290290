// src/Components/Footer.js
import React from "react";
import image from "../assets/LoginLogo.svg"; // Adjust the path as necessary
import styles from "./styles.module.css"; // Assuming you have some CSS for the footer

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <img src={image} alt="Logo" className={styles.footerLogo} />
    </footer>
  );
};

export default Footer;
