import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import Router from "./Router";
import ErrorBoundary from "./Components/errorBoundary/ErrorBoundary";
// import Footer from "./routes/Footer";
import { AuthProvider } from "./context/authContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#dfdfdf",
    },
    secondary: {
      main: "#FFFFF",
    },
  },
});

function App() {
  return (
    <div className="bg-[#e8dfd9]">
      <AuthProvider>
        <BrowserRouter>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <Router />
              {/* <Footer /> */}
            </ThemeProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
