import React from "react";
import { useLocation, useHistory } from "react-router-dom";

export const setToken = (token, user) => {
  localStorage.setItem("jwtToken", token); // Make up your own token
  localStorage.setItem("user", user);
};

export const fetchToken = () => {
  return localStorage.getItem("jwtToken");
};

export function RequireToken({ children }) {
  const history = useHistory();
  const location = useLocation();
  const auth = fetchToken();

  if (!auth) {
    history.push({
      pathname: "/",
      state: { from: location },
    });
    return null;
  }

  return children;
}
