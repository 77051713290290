import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RequireToken } from "../src/context/auth";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ProtectedRoute from "./HOC/protectedRoute";
const Login = lazy(() => import("./routes/Login"));
const Home = lazy(() => import("./routes/Home"));
const Report = lazy(() => import("./routes/Report"));
const StepProgressBar = lazy(() => import("./Components/StepProgressBar"));
import image from "../src/assets/LoginLogo.svg";
import styles from "../src/routes/styles.module.css";
const renderLoader = () => (
  <main className="d-flex justify-content-center">
    <CircularProgress />
  </main>
);

export default function Router() {
  return (
    <Box sx={{ display: "flex" }}>
      <Suspense fallback={renderLoader()}>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/login" component={Login} /> */}
          {/* <ProtectedRoute exact path="/report" component={Report} />
          <ProtectedRoute exact path="/start" component={StepProgressBar} /> */}
          <ProtectedRoute exact path="/report" component={Report} />
          <ProtectedRoute exact path="/start" component={StepProgressBar} />
          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      </Suspense>
    </Box>
  );
}
