import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const API_HOST = process.env.REACT_APP_API_HOST;
const HOME = process.env.REACT_APP_HOME;

export { API_HOST };
export { HOME };
export const HOME_TAB = "Home";
export const SEARCH_TAB = "Search";
export const HOMECOLOR = "#DA2027";
export const stepsData = {
  FullName: [
    //    "Activate SlideFeeder",
    //    "Insert: Specimen blood smear slide",
    "Preview Camera Algorithm For Macroscopic WSI",
    "Engage main Camera And Autofocus",
    "Engage ROI Detection Algorithm",
    "Engage ROI Navigation",
    "Report",
  ],
  Name: [
    //    "Activate",
    //    "Insert",
    "Preview",
    "Autofocus",
    "ROI",
    "Image",
    "Inferencing",
    "Report",
  ],
  status: ["Starting", "processing", "Completed"],
};
export const semiAutoStepsData = {
  FullName: [
    //    "Activate SlideFeeder",
    //    "Insert: Specimen blood smear slide",
    // "Preview Camera Algorithm For Macroscopic WSI",
    // "Engage main Camera And Autofocus",
    "Image",
    "Inferencing",
    "Report Generated",
  ],
  Name: [
    //    "Activate",
    //    "Insert",
    // "Preview",
    // "Autofocus",
    // "ROI",
    "Image",
    "Inferencing",
    "Report",
  ],
  status: ["Starting", "processing", "Completed"],
};
export const downloadPDF = async () => {
  const input = document.getElementById("report-table");
  if (!input) {
    console.error("Element with ID 'report-table' not found.");
    return;
  }

  try {
    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    // Get current date and time
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 10); // Format: YYYY-MM-DD
    const formattedTime = now.toTimeString().slice(0, 8).replace(/:/g, "-"); // Format: HH-MM-SS

    // Filename with date and time
    const filename = `report_${formattedDate}_${formattedTime}.pdf`;

    pdf.save(filename);
  } catch (error) {
    console.error("Error generating PDF: ", error);
  }
};
export const conditions = [
  {
    "Few/1+": "<=10",
    "Moderate/2+": ">10 && <=20",
    "Many/3+": ">20",
  },
  { "Few/1+": "<=1", "Moderate/2+": "2-5", "Many/3+": ">5" },
  { "Few/1+": "<5", "Moderate/2+": ">4 && <=20", "Many/3+": ">20" },
  { "Few/1+": "<1%", "Moderate/2+": ">0 && <=2", "Many/3+": ">2" },
  // { "Few/1+": "<5", "Moderate/2+": ">4 && <=20", "Many/3+": ">20" },
];
export const result = {
  data: [
    {
      Class: "1",
      Count: "0",
      Percentage: "0.0",
    },
    {
      Class: "2",
      Count: "1",
      Percentage: "0.0758150113722517",
    },
    {
      Class: "3",
      Count: "23",
      Percentage: "1.7437452615617892",
    },
    {
      Class: "4",
      Count: "264",
      Percentage: "20.01516300227445",
    },
    {
      Class: "5",
      Count: "1",
      Percentage: "0.0758150113722517",
    },
    {
      Class: "6",
      Count: "814",
      Percentage: "6.713419257012895",
    },
    {
      Class: "7",
      Count: "22",
      Percentage: "1.6679302501895377",
    },
    {
      Class: "8",
      Count: "106",
      Percentage: "8.03639120545868",
    },
    {
      Class: "9",
      Count: "88",
      Percentage: "6.671721000758151",
    },
    {
      Class: "10",
      Count: "0",
      Percentage: "0.0",
    },
    {
      Class: "11",
      Count: "0",
      Percentage: "0.0",
    },
    {
      Class: "Total",
      Count: "1319",
      Percentage: "nan",
    },
    {
      Class: "WBC Class",
      Count: "Count",
      Percentage: "Percentage",
    },
    {
      Class: "12",
      Count: "34",
      Percentage: "34.34343434343434",
    },
    {
      Class: "13",
      Count: "21",
      Percentage: "21.21212121212121",
    },
    {
      Class: "14",
      Count: "10",
      Percentage: "10.1010101010101",
    },
    {
      Class: "15",
      Count: "34",
      Percentage: "34.34343434343434",
    },
    {
      Class: "16",
      Count: "0",
      Percentage: "0.0",
    },
    {
      Class: "Total",
      Count: "99",
      Percentage: "nan",
    },
    {
      Class: "Platelet Class",
      Count: "Count",
      Percentage: "Percentage",
    },
    {
      Class: "17",
      Count: "77",
      Percentage: "100.0",
    },
    {
      Class: "total_counts",
      Count: "77",
      Percentage: "77",
    },
    {
      Class: "Total",
      Count: "77",
      Percentage: "nan",
    },
  ],
  status: 200,
};
// Report data
export const reports = {
  CLASS: [
    {
      "CLASS ID": 1,
      "CLASS NAME": "MICROCYTIC NORMOCHROMIC",
      "CLASS VALUE %": 0,
    },
    {
      "CLASS ID": 2,
      "CLASS NAME": "MICROCYTIC HYPOCHROMIC",
      "CLASS VALUE %": 0.075815011,
    },
    {
      "CLASS ID": 3,
      "CLASS NAME": "SPHEROCYTE",
      "CLASS VALUE %": 1.743745262,
    },
    {
      "CLASS ID": 4,
      "CLASS NAME": "NORMOCYTIC NORMOCHROMIC",
      "CLASS VALUE %": 20.015163,
    },
    {
      "CLASS ID": 5,
      "CLASS NAME": "NORMOCYTIC HYPOCHROMIC",
      "CLASS VALUE %": 0.075815011,
    },
    { "CLASS ID": 6, "CLASS NAME": "MACROCYTE", "CLASS VALUE %": 61.71341926 },
    {
      "CLASS ID": 7,
      "CLASS NAME": "HYPOCHROMIC MACROCYTE",
      "CLASS VALUE %": 1.66793025,
    },
    {
      "CLASS ID": 8,
      "CLASS NAME": "ELLIPTOCYTE",
      "CLASS VALUE %": 8.036391205,
    },
    {
      "CLASS ID": 9,
      "CLASS NAME": "SCHISTOCYTE",
      "CLASS VALUE %": 6.671721001,
    },
    { "CLASS ID": 10, "CLASS NAME": "DACROCYTE", "CLASS VALUE %": 0 },
    { "CLASS ID": 11, "CLASS NAME": "ACANTHOCYTE", "CLASS VALUE %": 0 },

    {
      "CLASS ID": 12,
      "CLASS NAME": "NEUTROPHIL",
      "CLASS VALUE %": 34.34343434,
    },
    {
      "CLASS ID": 13,
      "CLASS NAME": "LYMPHOCYTE",
      "CLASS VALUE %": 21.21212121,
    },
    { "CLASS ID": 14, "CLASS NAME": "MONOCYTE", "CLASS VALUE %": 10.1010101 },
    {
      "CLASS ID": 15,
      "CLASS NAME": "EOSINOPHIL",
      "CLASS VALUE %": 34.34343434,
    },
    { "CLASS ID": 16, "CLASS NAME": "BASOPHIL", "CLASS VALUE %": 0 },
    { "CLASS ID": 17, "CLASS NAME": "PLATELET", "CLASS VALUE %": 77 }, //here we pick count not percentage
  ],
};
export const DERIVED_CLASS_PERCENTAGES = {
  HYPOCHROMIC_CELLS:
    "(CLASS_2 + CLASS_5 + CLASS_7) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7) * 100",
  MACROCYTES:
    "(CLASS_6 + CLASS_7) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7) * 100",
  MICROCYTES:
    "(CLASS_1 + CLASS_2) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7) * 100",
  NORMOCYTES:
    "(CLASS_5) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7) * 100",
  ANISOCYTOSIS: "(CLASS_1 + CLASS_2 + CLASS_6 + CLASS_7) / (CLASS_5) * 100",
  POIKILOCYTOSIS: "(CLASS_8 + CLASS_9 + CLASS_10 + CLASS_11) / (CLASS_5) * 100",
  ELLIPTOCYTE:
    "(CLASS_8) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7 + CLASS_9 + CLASS_10 + CLASS_11) * 100",
  SCHISTOCYTE:
    "(CLASS_9) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7 + CLASS_8 + CLASS_10 + CLASS_11) * 100",
  DACROCYTE:
    "(CLASS_10) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7 + CLASS_8 + CLASS_9 + CLASS_11) * 100",
  ACANTHOCYTE:
    "(CLASS_11) / (CLASS_1 + CLASS_2 + CLASS_5 + CLASS_6 + CLASS_7 + CLASS_8 + CLASS_9 + CLASS_10) * 100",
  NEUTROPHIL:
    "(CLASS_12) / (CLASS_12 + CLASS_13 + CLASS_14 + CLASS_15 + CLASS_16) * 100",
  LYMPHOCYTE:
    "(CLASS_13) / (CLASS_12 + CLASS_13 + CLASS_14 + CLASS_15 + CLASS_16) * 100",
  MONOCYTE:
    "(CLASS_14) / (CLASS_12 + CLASS_13 + CLASS_14 + CLASS_15 + CLASS_16) * 100",
  EOSINOPHIL:
    "(CLASS_15) / (CLASS_12 + CLASS_13 + CLASS_14 + CLASS_15 + CLASS_16) * 100",
  BASOPHIL:
    "(CLASS_16) / (CLASS_12 + CLASS_13 + CLASS_14 + CLASS_15 + CLASS_16) * 100",
  PLATELET_ESTIMATE: "(CLASS_17)",
  SPHEROCYTE: "(CLASS_3)",
};
export const Head1 = ["Cell Name", "Few/1+", "Moderate/2+", "Many/3+"]; //main Heading
export const Head2 = [
  "RBC Morphology pre-characterization",
  "Grading",
  "Percentage",
  "Percentage",
  "Percentage",
];
export const Head3 = ["RBC Morphology subtypes", "", "", "", ""];

export const Head4 = ["Platelet Estimate", "Adequate/Thrombocytopenia"]; //it has only one header where Adequate/Thrombocytopenia an one of the given .
export const Head5 = ["WBC classification", "%", "Normal Range %"];
export const preCharacterization = [
  ["Anisocytosis", "", "<=10", "11-20", ">20"],
  ["Macrocytes", "", "<=10", "11-20", ">20"],
  ["Oval macrocytes", "", "<=1", "2-5", ">5"],
  ["Microcytes", "", "<=10", "11-20", ">20"],
  ["Hypochromic cells", "", "<=10", "11-20", ">20"],
]; //RBC Morphology pre-characterization cell data
export const subtypes = [
  ["Poikilocytosis", "", "<=10", "11-20", ">20"],
  ["Echinocytes", "", "<5", "5-20", ">20"],
  ["Schistocytes", "", "<1%", "1-2", ">2"],
  ["Spherocytes", "", "<5", "5-20", ">20"],
  ["Dacrocytes", "", "<5", "5-20", ">20"],
]; //RBC Morphology subtypes cell data
export const WBCClassification = [
  ["Neutrophils", "", "40-70"],
  ["Lymphocytes", "", "25-45"],
  ["Monocytes", "", "2-10"],
  ["Eosinophils", "", "2-6"],
  ["Basophils", "", "0.1-1"],
]; //WBC classification cell data
